.informationWrapper {
  display: flex;
  gap: 32px;
  margin-bottom: 32px;
  @media (max-width: 1260px) {
    flex-wrap: wrap;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
  & svg {
    fill: transparent;
  }
  & .contentWrapper {
    display: flex;
    gap: 16px;
    width: calc(100% / 3);
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  & h6 {
    color: var(--Apps-Apps_Black, #22313f);
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: initial;
    margin: 0 0 7px;
    padding: 0;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  & p {
    color: var(--Black-Shade-Para_Text, #949494);
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0;
    margin: 0;
    & .timeText {
      color: var(--Web-H-2, #2e835f);
      display: block;
    }
  }
}
.titleTextBorder {
  margin: 0;
  padding: 0;
  color: var(--Web-Primary, #3d70a1);
  font-family: Nunito;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 14px;
  margin-bottom: 24px;
  @media (max-width: 767px) {
    font-size: 22px;
  } 
}
