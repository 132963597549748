.categoryWrapper {
  display: flex;
  gap: 20px;
  margin-bottom: 32px;
  @media (max-width: 1260px) {
    flex-wrap: wrap;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 12px;
  }
  & .categoryText {
    width: calc((100% / 3) - 40px);
    position: relative;
    margin-left: 39px;
    color: var(--Black-Shade-Text_Black, #272727);
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 767px) {
      font-size: 18px;
      width: calc(100% - 48px);
    }
    &:after {
      position: absolute;
      background: #272727;
      height: 11px;
      width: 11px;
      left: -24px;
      top: 8px;
      content: '';
      border-radius: 100px;
      @media (max-width: 767px) {
        height: 8px;
        width: 8px;
        left: -21px;
        top: 10px;
      }
    }
  }
  & .subCategoryText {
    width: calc((100% / 3) - 40px);
    margin-left: 16px;
    @media (max-width: 767px) {
      width: 100%;
    }
    & .subTitle {
      color: var(--Web-H-1, #e28415);
      font-family: Nunito;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0 0 14px;
      @media (max-width: 767px) {
        font-size: 16px;
        padding: 0;
        margin-bottom: 7px;
      }
    }
    & .subListText {
      position: relative;
      /* margin-left: 23px; */
      color: var(--Black-Shade-Para_Text, #949494);
      font-family: Nunito;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: block;
      padding: 0;
      margin-bottom: 11px;
      @media (max-width: 767px) {
        font-size: 16px;
        margin: 0;
      }
      & li{
        color: var(--Black-Shade-Para_Text, #949494);
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: block;
        position: relative;
        @media (max-width: 767px) {
          font-size: 16px;
        }
        &:before{
          content: "•";
          /* vertical-align: top; */
          margin-right: 10px;
          font-size: 30px;
          top: -5px;
          position: absolute;
          left: -23px;
          color: #949494;
          @media (max-width: 767px) {
            top: -5px;
            font-size: 22px;
            left: -21px;
          }
        }
      }
      /* &:after {
        position: absolute;
        background: #949494;
        height: 11px;
        width: 11px;
        left: -24px;
        top: 8px;
        content: '';
        border-radius: 100px;
      } */
    }
    & .subListTextList{
      position: relative;
      margin-left: 23px;
      list-style-type: disc;
      
      @media (max-width: 767px) {
        font-size: 16px;
        margin-left: 32px;
      }
      & li{
        color: var(--Black-Shade-Para_Text, #949494);
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: block;
        position: relative;
        @media (max-width: 767px) {
          font-size: 16px;
        }
        &:before{
          content: "•";
          /* vertical-align: top; */
          margin-right: 10px;
          font-size: 30px;
          top: -5px;
          position: absolute;
          left: -23px;
          color: #949494;
          @media (max-width: 767px) {
            top: -5px;
            font-size: 22px;
            left: -21px;
          }
        }
      }
    }
  }
}
.titleTextBorder {
  margin: 0;
  padding: 0;
  color: var(--Web-Primary, #3d70a1);
  font-family: Nunito;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 14px;
  margin-bottom: 24px;
  @media (max-width: 767px) {
    font-size: 22px;
  }
}
